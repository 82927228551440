import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: any;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.auth.getAccessTokenSilently().subscribe((res: any) => {
      this.token = res;      
      sessionStorage.setItem('jwtToken', this.token);
    });    
  }

  getToken(): Observable<any> {
    return this.token;
  }

  getUserByAuthId(): Observable<any> {
    const headers = new HttpHeaders({
      authId: sessionStorage.getItem('id'),
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
      headers: headers,
    });
  }

  getUserId(): any {
    this.auth.user$.subscribe((user: any) => {
      sessionStorage.setItem('id', user?.sub);
      const headers = new HttpHeaders({
        authId: sessionStorage.getItem('id'),
      });
      return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
        headers: headers,
      });
    });
  }
}
