<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div class="mainContainer">
  <div class="wrpr">
    <h1>Data Quest</h1>
    <div class="mainCard">
        <h2>{{ viewsLists.displayViewHeading }}</h2>
     

      <div class="viewDetailCont">
        <h5>Description</h5>
        <p>
          {{ viewsLists.description }}
        </p>
      </div>
      <div class="viewDetailCont">
        <h5 class="tagsHeading">Related Tags</h5>
        <div class="addedTags">
        
          <div class="tags">
            <span class="dqTag" *ngFor="let tag of tagsByViewId">{{tag.tagName}}
        
                <img src="../../../../assets/images/close1.svg" alt="" (click)="removeTags(tag.id)">
              </span>
          </div>
          <button class="addTagbtn" *ngIf="!addtagFlag" (click)="showAddInput()">
            <img src="../../../../assets/images/Group.svg" alt="" />
            Add Tag
          </button>
          <div *ngIf="addtagFlag"  class="tag-autocomplete">
            <ng-autocomplete [data]="allTags" [searchKeyword]="keyword" placeholder="Enter the Tag Name"
              (selected)="selectEvent($event)" (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)"
              [itemTemplate]="itemTemplate" class="autoComplete" [notFoundTemplate]="notFoundTemplate" #auto (keyup.enter)="addNewTag($event)" (keyup)="setText($event)">
            </ng-autocomplete>
      
            <ng-template #itemTemplate let-item>
              <div>
                <a [innerHTML]="item.name"></a> <span><img src="../../../../assets/images/addTagIcon.svg" /></span>
              </div>
            </ng-template>
      
            <ng-template #notFoundTemplate let-notFound>
              <div>
                <a (click)="addNewTag(autocompleteText,true)" class="notFoundTag">
                   <span>Create "{{autocompleteText}}"</span>
                  <span><img src="../../../../assets/images/addTagIcon.svg" /></span></a> 
              </div>
            </ng-template>
      
          </div>
        </div>
      </div>
      <div
        class="previewCont"
        [ngClass]="
          errorMessage ? 'errorState' : '' || warningMessage ? 'errorState' : ''
        "
        id="run_preview"
      >
        <div class="previewSectionHead">
          <h4>Preview</h4>
        </div>
        
        <div class="error" *ngIf="errorMessage">
          <span (click)="onErrorClose()" title="close" onKeyPress=""
            ><img
              src="../../../../assets/images/Close.svg"
              alt="close_icon"
              class="icon"
          /></span>
          <img src="../../../../assets/images/warning.svg" alt="warning_icon" />
          {{ errorMessage }}
        </div>

        <div class="error cstm-error" *ngIf="warningMessage">
          <span (click)="onErrorClose()" title="close" onKeyPress=""
            ><img
              src="../../../../assets/images/Close.svg"
              alt="close_icon"
              class="icon"
          /></span>
          {{ warningMessage }}
        </div>

        <div class="table" [ngClass]="previewResults.length ? 'addHeight' : ''">
          <div class="scrolableTable">
            <ng-container *ngIf="!previewResults.length">
              <p>Loading...</p>
            </ng-container>
            <div class="thead">
              <div class="th" *ngFor="let key of getPreviewKeys()">
                <div class="cellData">
                  {{ key | titlecase }}
                </div>
              </div>
            </div>
            <div class="tbody">
              <div class="tr" *ngFor="let previewResult of previewResults">
                <div class="td" *ngFor="let key of getPreviewKeys()">
                  <div class="cellData">
                    {{ previewResult[key] ? previewResult[key] : "-" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="requestForm">
          <div class="logicCont">
            <div class="logicContHead">
              <h4>Narrow database with the following properties</h4>
              <div></div>
            </div>
            <div class="reqDetails">
              <div class="labelThisRequest" id="fitler-3">
                <p class="label">Label</p>
                <input
                  type="text"
                  name="value"
                  [value]="customLabel"
                  (keyup)="setCustomLabel($event)"
                  class="inputField"
                  placeholder="Type value"
                /> 
              </div>
              <div></div>
              <h5 *ngIf='requestId'>Request ID <span>{{requestId}}</span> updated {{ timeInterval }} ago</h5>

            </div>
            <div class="logicsCrud">
              <div class="logicSet" formArrayName="allFields">
                <ng-container
                  *ngFor="let customControl of getControls(); let i = index"
                  [formGroupName]="i"
                >
                  <div class="functionCont" *ngIf="i > 0">
                    <hr />
                    <label class="fxSwitch">
                      <input
                        type="checkbox"
                        class="fxInput"
                        formControlName="andOrValue"
                        [value]="isChecked"
                        (change)="toggleClick(rowIndex)"
                      />
                      <span class="toggle"></span>
                    </label>
                    <hr />
                  </div>
                  <div class="cstm-tggl-cont">
                    <p class="label">Type</p>
                    <label class="fxSwitch" for="tglType">
                      <span class="toggle filters" id="tglType">
                        <span
                          *ngFor="let tabOption of tabOptions; let j = index"
                          class="tgl-btn"
                          [class.active]="formlist[i] === j"
                          onKeyDown=""
                          (click)="onClickSetValue(tabOption, i, j,true,allFields.value[i])"
                          >{{ tabOption }}</span
                        >
                      </span>
                    </label>
                  </div>
                  <ng-container *ngIf="formlist[i] === 0">
                    <div class="cstm-select-cont fltr">
                      <p class="label">Field Name</p>
                      <ng-select [items]="fields"
                        bindLabel="displayName"
                        bindValue="displayName"
                        placeholder="Select"
                        class="inputField"
                        formControlName="fieldName"
                        required
                      >
                      </ng-select>
                    </div>
                    <div class="cstm-select-cont fltr">
                      <p class="label">Operator(s)</p>
                      <ng-select
                        class="inputField"
                        placeholder="Select"
                        formControlName="operator"
                        required
                      >
                        <ng-option
                          *ngFor="let operator of operators"
                          [value]="operator.operator_name"
                          >{{ operator.operator_name }}
                          {{ operator.display_name }}</ng-option
                        >
                      </ng-select>
                    </div>
                    <div class="cstm-select-cont fltr">
                      <p class="label">Value(s)</p>
                      <input
                        type="text"
                        name="value"
                        class="inputField custom-padding-values"
                        placeholder="Type here"
                        formControlName="fieldValue"
                        required
                      />
                    </div>
                  </ng-container>
                  <ng-container *ngIf="formlist[i] === 1">
                    <div class="cstm-sql-cont fltr">
                      <p class="label">Enter SQL command</p>
                      <input
                        type="text"
                        name="value"
                        class="inputField sql-input-field"
                        placeholder="“date_created” > ‘2023-08-21’ > wpdb_myrecord.sql"
                        formControlName="customQuery"
                        required
                      />
                    </div>
                  </ng-container>
                  <div class="actionBox">
                    <button
                      class="deleteBtn"
                      [ngClass]="
                        allFields.length === 1
                          ? 'setGreyBorder'
                          : 'setRedBorder'
                      "
                      type="button"
                      (click)="onDeleteRow(i)"
                    >
                      <img
                        src="../../../../assets/images/{{
                          allFields.length === 1 ? 'delete-grey' : 'Delete'
                        }}.svg"
                        alt="delete_icon"
                      />
                    </button>
                  </div>
                </ng-container>
              </div>
              <div class="nextRow">
                <div
                  class="tertiary-btn-with-icon"
                  (click)="onAddNew()"
                  onKeyDown=""
                >
                  <img
                    src="../../../../assets/images/East-blue.svg"
                    alt="ease_blue_icon"
                  />
                  Add New
                </div>
                <button
                  class="secondary-btn-filled"
                  type="submit"
                  (click)="onApply()"
                  [disabled]="!isPreviewEnabled()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div class="ctaCont">
            <button class="tertiary-btn" type="button" (click)="goBack()">
              <img
                src="../../../../assets/images/East-Green.svg"
                alt="ease_green_icon"
              />
            </button>

           <div class="next">
              <button class="secondary_green_btn"
              type="button"
              (click)="onSaveDraft()"
              [disabled]="
                isSaveDisabled()
              ">SAVE</button>


              <button
                class="primary-btn"
                type="button"
                
                (click)="onSubmit()"
                [disabled]="
                  !isSubmitButtonDisabled &&
                  isSubmitEnabled() &&
                  isSubmitDisabledYes()
                "
              >
                Submit
              </button>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  </div>
</div>

<app-success-toast
  [message]="successMessage"
  *ngIf="successMessage"
></app-success-toast>

<app-error-toast
  [message]="errorMessageSubmit"
  *ngIf="errorMessageSubmit"
></app-error-toast>
