import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SaveDraftRequest } from './draftrequest.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DetailsService {
  viewId:Subject<any>= new Subject<any>();
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;',
    }),
  };

  getPreviewResults(
    userId: any,
    viewType: any,
    viewName: any,
    condition: any,
    limit: any,
    page: any,
    customLabel:any,
    viewId:any,
    authId:any,
    requestId:any,
    filters:any
  ) {
    let checkNull=false;
    if(filters.length>0){
      if(filters[0].customQuery==null && filters[0].fieldName==null){
        checkNull=true
      }else{
        checkNull=false
      }
    }
    let payload={
      "userId": userId,
      "viewId": viewId,
      "authId": authId,
      "userInput": checkNull?null:{
          "condition":condition,
          "customLabel": customLabel,
          "filters": filters
      },
      "requestId": requestId,
      "limit":100,
      "page":1
  }
    return this.http.post<any>(
      `${environment.apiUrl}/v1/viewResultsPreview`,
      payload,
      {}
    );
  }

  getField(viewId: any, userId: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/viewFields`,
      { viewId, userId },
      {}
    );
  }

  getOperators() {
    return this.http.post<any>(`${environment.apiUrl}/v1/operators`, {});
  }

  getSubmit(
    userId: any,
    condition: any,
    requestId: any,
    customLabel: any,
    viewId: any,
    authId: any,
    filters: any
  ) {
    const headers = new HttpHeaders({
      parentToken: 'Bearer ' + sessionStorage.getItem('parentToken'),
    });
    let payload = {
      "userId": userId,
      "viewId": viewId,
      "authId": authId,
      "userInput": {
        "condition": condition,
        "customLabel": customLabel,
        "filters": filters
      },
      "requestId": requestId,
      "limit":100,
      "page":1
    }
    if (requestId === null || requestId === undefined)
      return this.http.post<any>(
        `${environment.apiUrl}/v1/submit`,
        payload,
        { headers: headers }
      );
    else {
      requestId = 'DataQuest-' + requestId;
      payload.requestId=requestId
      return this.http.post<any>(
        `${environment.apiUrl}/v1/submit`,
        payload,
        { headers: headers }
      );
    }
  }

  getSaveDraft(
    request: SaveDraftRequest
  ) {
    const headers = new HttpHeaders({
      parentToken: 'Bearer ' + sessionStorage.getItem('parentToken'),
    });
    return this.http.post<any>(
      `${environment.apiUrl}/v1/saveDraft`,
      request,
      { headers: headers }
    );
  }

  getEditRequestDetails(reqId: any) {
    // let _URL = `https://dmintplatformapi-dev.azurewebsites.net/dmint-platform/api/v1/editRequest`;
    let _URL = `${environment.apiUrl}/v1/editRequest`;
    let payload = {
      "authId": sessionStorage.getItem('id'),
      "requestId": reqId
    };
    const headers = new HttpHeaders({
      parentToken: 'Bearer ' + sessionStorage.getItem('parentToken'),
    });

    return this.http.post<any>(
      _URL,
      payload,
      { headers: headers }
    );
  }


  // getAllTags
  getAllTags() {
    let URL = `${environment.apiUrl}/v1/allTags`;
    let payload = {};
    return this.http.post<any>(
      URL,
      payload
    );
  }
  // getTagsByView
  getTagsByView(viewId:any) {
    let URL = `${environment.apiUrl}/v1/viewTags`;
    let payload = {
      "viewId": viewId
    };
    return this.http.post<any>(
      URL,
      payload
    );
  }
  //addTags
  addTags(viewId:any,tagIds:any,tagNames:any) {
    let URL = `${environment.apiUrl}/v1/addTags`;
    let payload = {
      "viewId": viewId,
      "tagIds": [
        ...tagIds
      ],
      "tagNames": [
        ...tagNames
      ]
    };
    return this.http.post<any>(
      URL,
      payload
    );
  }
  //removeTags
  removeTags(viewId:any,tagId:any) {
    let URL = `${environment.apiUrl}/v1/removeTags`;
    let payload = {
      "tagId": tagId,
      "viewId": viewId
    };
    return this.http.post<any>(
      URL,
      payload
    );
  }
}
