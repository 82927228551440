export const environment = {
  production: false,
  type: window.location.origin,
  // apiUrl: 'https://dataquestapi-dev.azurewebsites.net/data-quest/api',
  //apiUrl: 'https://dataquestapi-qa.azurewebsites.net/data-quest/api',
  apiUrl: `${document.location.protocol}//${document.location.host}/data-quest/api`,
  auth: {
    domain: 'dev-c2oymhxblfvzum2l.eu.auth0.com',
    clientId: 'u11wynxpxH9Rf5xh6nOuCsDwWeQkMxOt',
    clientSecret:
      'nnOb9PjR69Lssb9m5jccF90zHQTm4sprKgb-qQ6Q7ZfOJuU-4YxVpWtMdWz3hRV8',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dataquest-dev.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  indusAuth: {
    domain: 'dacoval-qa.eu.auth0.com',
    clientId: '205d7gnsz1uIJZBmuUkbGy6UjVzHwGNk',
    clientSecret:
      'T1O9mzzfVxVz23MOLxTmJ4g0WYaWAYgSqhb7ulOGZQS0_KVd1YK0sjGtTAjZ_wC7',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dataquest-qa.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  uatAuth: {
    domain: 'dacoval-dev.eu.auth0.com',
    clientId: 't5Dox8OFWXq3Ca0sTfGbqdnaeqcQ1cyc',
    clientSecret:
      '5QsRw7YHWiOOkW3K5pAM5x3chfib9J6_dknceYOD74voB8fFsu3T6lQxNnbvoSQf',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dataquest-uat.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
};
