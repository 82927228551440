import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { HomeService } from './home.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tags: any = [];
  categories: any = [];
  viewsLists: any = [];
  getViewsLength: number = 0;
  userId: number = null;
  dataQuestForm!: FormGroup;
  searchValue: any;
  getCategoryId: any;
  selectedValues: any[] = [];
  getTags: any = [];
  error: any = null;
  isError: boolean = false;
  @ViewChildren('checkBoxInput')
  checkBoxInputs!: QueryList<any>;
  isChecked: any[] = [];
  isDropdownMenuVisible: boolean = false;
  tagIndexStore: any;
  tagsMap: Map<string, string[]> = new Map();

  constructor(
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userId = +sessionStorage.getItem('userId');    
    this.buildForm();
    this.loadViewTypes();
    this.loadTags();
    this.loadViews();

    this.utilService.SharingError.subscribe((error: any) => {
      this.error = error;
    });
  }

  buildForm() {
    this.dataQuestForm = this.fb.group({
      userId: new FormControl(this.userId),
      searchElement: new FormControl(),
      viewType: new FormControl(),
      tags: new FormArray([]),
    });
  }

  loadViewTypes() {
    this.homeService.getViewTypes(this.userId).subscribe((res: any) => {
      const categoryList = res;
      this.categories = categoryList.result;
      this.spinner.hide();
    });
  }

  loadTags() {
    this.homeService.getAllTags(this.userId).subscribe((res: any) => {
      const tagLists = res;
      this.tags = tagLists.result;
      this.spinner.hide();
    });
  }

  loadViews() {
    this.homeService.getAllViews(this.userId).subscribe((res: any) => {
      this.isError = false;
      const viewRes = res;
      const viewResResult = viewRes.result;
      this.getViewsLength = viewRes.result.length;
      this.viewsLists = viewResResult;
      this.viewsLists.forEach((input: any) => {
        var tagsList = input.tags.split(",");
        this.tagsMap.set(input.id, tagsList);
      });      
      this.spinner.hide();
    });
  }

  onSearchClose() {
    this.spinner.show();
    this.searchValue = null;
    if (
      this.searchValue === null &&
      this.getCategoryId === null &&
      !this.getTags
    ) {
      this.loadViews();
    } else {
      this.loadSearchResults();
    }
  }

  getSearchText(valueFromSearch: any) {
    this.spinner.show();
    this.searchValue = valueFromSearch;
    if (this.searchValue === '') {
      this.searchValue = undefined;
    }
    if (
      this.getTags === undefined &&
      this.searchValue === undefined &&
      this.getCategoryId === undefined
    ) {
      this.loadViews();
    } else {
      this.loadSearchResults();
    }
  }

  onSelectCategory(categorieId: any) {
    this.spinner.show();
    this.getCategoryId = categorieId;
    this.loadSearchResults();
  }

  onSelectTags(e: any, tagIndex: any, tagId: number) {
    this.tagIndexStore = tagIndex;
    this.spinner.show();
    if (e.target.checked) {
      this.getTags.push(tagId);
      this.isChecked[tagIndex] = true;
      this.spinner.hide();
    } else {
      const idx = this.getTags.indexOf(tagId);
      if (idx !== -1) {
        this.getTags.splice(idx, 1);
        this.isChecked[tagIndex] = false;
      }
    }
    this.commonSearchLoad();
  }

  loadSearchResults() {
    this.spinner.show();
    const searchElement = this.searchValue;
    const viewType = this.getCategoryId;
    const tags = this.getTags;

    if (!searchElement && viewType === null && !tags) {
      this.loadViews();
    } else {
      this.homeService
        .getViewsOnSearch(this.userId, searchElement, viewType, tags)
        .subscribe({
          next: (res: any) => {
            const viewRes = res;
            const viewResResult = viewRes.result;
            this.getViewsLength = viewRes.result.length;
            this.viewsLists = viewResResult;
            this.isError = false;
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            this.getViewsLength = 0;
            this.isError = true;
          },
        });
    }
  }

  onDropdownMenuVisible() {
    this.isDropdownMenuVisible = !this.isDropdownMenuVisible;
  }

  onCategoryReset() {
    this.dataQuestForm.get('viewType')?.reset();
    this.getCategoryId = null;
    this.loadSearchResults();
  }

  onTagsReset() {
    this.spinner.show();
    this.checkBoxInputs.forEach((input) => {
      input.nativeElement.checked = false;
      this.isChecked = [];
    });
    this.getTags = [];
    this.commonSearchLoad();
  }

  commonSearchLoad() {
    if (
      this.getTags.length === 0 &&
      this.searchValue === undefined &&
      this.getCategoryId === undefined
    ) {
      this.loadViews();
    } else {
      this.loadSearchResults();
    }
  }

  /* @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.dropdownCont')) {
      this.isDropdownMenuVisible = false;
    }
  } */
}
