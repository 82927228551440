import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  typeSelected: string;
  subscription: Subscription;
  token: any;
  error: string = null;
  lastVisitedUrl: string;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private location: Location,
    private auth: AuthService,
    public router: Router
  ) {
    this.spinner.show();
    this.typeSelected = 'ball-clip-rotate-pulse';
    this.auth.getAccessTokenSilently().subscribe((res: any) => {
      localStorage.setItem('token', res);
      if (!sessionStorage.getItem('jwtToken')) {
        sessionStorage.setItem('jwtToken', localStorage.getItem('token'));
      }
    });
  }

  getUserByAuthId(): Observable<any> {
    const headers = new HttpHeaders({
      authId: localStorage.getItem('id'),
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
      headers: headers,
    });
  }

  ngOnInit(): void {
    this.spinner.show();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params.token;
      if (token != undefined) {
        sessionStorage.setItem('parentToken', token);
       
        const pathSnap = this.activatedRoute.snapshot as any;        
        this.lastVisitedUrl = pathSnap['_routerState'].url;  
        sessionStorage.setItem('lastVisitedUrl', this.lastVisitedUrl);                       
        if(this.lastVisitedUrl?.includes('home')){
          this.cleanURL('home');
        }
      }
    });

    if (sessionStorage.getItem('userId')) {
      return;
    } else {
      this.auth.user$.subscribe((user) => {
        const authId = user?.sub;
        if (authId != undefined && authId.length > 0) {
          sessionStorage.setItem('id', authId);
          const headers = new HttpHeaders({
            authId: sessionStorage.getItem('id'),
          });
          this.http
            .post<any>(`${environment.apiUrl}/v1/user`, null, {
              headers: headers,
            })
            .subscribe({
              next: (res: any) => {
                const id = res.result;
                sessionStorage.setItem('userId', id);
                this.spinner.hide();
                this.error = '';         
               this.lastVisitedUrl = sessionStorage.getItem('lastVisitedUrl');
               if(this.lastVisitedUrl?.includes('home')){                  
                  window.location.reload();
               } else {
                  this.router.navigateByUrl(this.lastVisitedUrl); 
                }            
              },
              error: (err: any) => {
                this.spinner.hide();
                if (err.status == 401 || err.status == 403) {
                  this.error =
                    'Authentication Failed! due to some technical error, Please try to relogin.';
                } else if (err.status.startsWith('5')) {
                  this.error =
                    'Service unavailable due to Internal Server Error.';
                }
              },
            });
        }
      });
    }
  }

  cleanURL(urlPath : any) {    
    this.location.replaceState(urlPath);
  }
}
