<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div class="mainContainer">
  <div class="wrpr">
    <h1>Data Quest</h1>
    <div class="mainCard">
      <div class="heading-box">
        <img
          src="../../../../assets/images/capabilities.svg"
          alt="capabilities-icon"
        />
        <h2>Dataviews</h2>
      </div>
      <form [formGroup]="dataQuestForm" (ngSubmit)="getSearchText(searchValue)">
        <div class="globalSearch">
          <img
            src="../../../../assets/images/search-icon.svg"
            alt="icon-for-search"
            class="searchIcon"
          />
          <input
            type="text"
            placeholder="Search by name or description"
            class="searchField"
            formControlName="searchElement"
            [value]="searchValue"
            [(ngModel)]="searchValue"
          />
          <span
            class="close_icon_search"
            *ngIf="searchValue"
            (click)="onSearchClose()"
            onKeyPress=""
            ><img
              src="../../../../assets/images/general-glyphs-close.svg"
              alt="close_icon"
          /></span>
        </div>
        <div class="fitlerCont">
          <h5>What are you looking for?</h5>
          <div class="categories_filter">
            <p class="label">Filter by Categories</p>
            <div class="category_box">
              <div class="radioSet">
                <ng-container
                  *ngFor="let category of categories; let i = index"
                >
                  <div class="tile" *ngIf="category.viewType">
                    <input
                      type="radio"
                      [value]="category.viewTypeId"
                      formControlName="viewType"
                      (change)="onSelectCategory(category.viewTypeId)"
                      id="{{ i }}"
                    />
                    <label for="{{ i }}">
                      {{ category.viewType }}
                    </label>
                  </div>
                </ng-container>
              </div>
              <div class="category_reset">
                <button
                  class="filter_reset"
                  type="button"
                  (click)="onCategoryReset()"
                  [disabled]="!getCategoryId"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div class="tags_filter">
            <p class="label">Filter by Tags</p>
            <div class="checkSet">
              <div class="collapsed">
                <div
                  class="tile"
                  formArrayName="tags"
                  *ngFor="let tag of tags; let i = index"
                >
                  <input
                    type="checkbox"
                    name="tags"
                    [value]="tag.tagName"
                    (change)="onSelectTags($event, i, tag.id)"
                    [id]="tag.tagName"
                    #checkBoxInput
                  />
                  <label [for]="tag.tagName" *ngIf="i < 7">
                    <p>
                      {{ tag.tagName }}
                      <span
                        ><img
                          src="../../../../assets/images/Close.svg"
                          alt="close_icon"
                          class="icon"
                      /></span>
                    </p>
                  </label>
                </div>
              </div>
              <div class="otherTags" *ngIf="tags.length > 7">
                <div class="dropdownCont">
                  <div
                    style="display: flex; align-items: center; width: 100%"
                    (click)="onDropdownMenuVisible()"
                    onKeyPress=""
                  >
                    +{{ tags.length - 7 }} others
                  </div>
                  <div class="dropdownOptionsCont">
                    <ul [ngClass]="isDropdownMenuVisible ? 'show' : 'hide'">
                      <div
                        formArrayName="tags"
                        *ngFor="let tag of tags; let i = index"
                      >
                        <li *ngIf="i >= 7">
                          <label
                            for="{{ tag.tagName }}"
                            (change)="onSelectTags($event, i, tag.id)"
                          >
                            <input
                              type="checkbox"
                              class="vanishCheck"
                              name="tags"
                              [checked]="isChecked[i]"
                              [value]="tag.tagName"
                              [id]="tag.tagName"
                              #checkBoxInput
                            />
                            <span class="styledCheck"></span>
                            {{ tag.tagName }}
                          </label>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                class="filter_reset"
                type="button"
                (click)="onTagsReset()"
                [disabled]="!getTags.length"
              >
                Reset
              </button>
            </div>
            <!-- <pre>{{ dataQuestForm.value | json }}</pre> -->
          </div>
        </div>
      </form>
      <div class="results_overview">
        <h2>{{ getViewsLength }} Dataviews</h2>
      </div>

      <div class="error-block" *ngIf="isError">
        <p>
          <img src="../../../../assets/images/warning.svg" alt="warning_icon" />
          {{ this.error }}
        </p>
      </div>

      <cdk-virtual-scroll-viewport
        [itemSize]="getViewsLength"
        class="viwport"
        [ngClass]="
          getViewsLength <= 3
            ? 'viewPort-height-3'
            : 'viewPort-max-height' && getViewsLength <= 6
            ? 'viewPort-height-6'
            : 'viewPort-max-height'
        "
        style="width: 100% !important; overflow-x: scroll"
        *ngIf="!isError"
      >
        <div class="cardGrid">
          <div *cdkVirtualFor="let viewList of viewsLists" class="item">
            <a [routerLink]="['/details', viewList.id]" class="dataviewCard">
              <div class="cardHeader">
                <h4 class="dataview_name">
                  {{ viewList.displayViewHeading }}
                </h4>
              </div>
              <p class="description">
                {{ viewList.description }}
              </p>
              <p class="relatedTags">
                Related Tags: <span *ngFor="let tag of tagsMap.get(viewList.id).slice(0,2); let count = index">{{tag}}<span *ngIf="count===0 && tagsMap.get(viewList.id).length >1">,</span></span>
                <span *ngIf="tagsMap.get(viewList.id).length > 2"> & {{tagsMap.get(viewList.id).length -2}} other tags </span>
              </p>  
              <div class="cardCTA">
                <div class="lastUp">
                  <div class="cardIcon xs backup"></div>
                  <p>
                    Last Updated:
                    <span>{{ viewList.lastUpdateDate }}</span>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
