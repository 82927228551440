import { BrowserModule } from '@angular/platform-browser';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './shared/auth-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownDirective } from './shared/dropdown.directive';
import { ErrorInterceptor } from './shared/error.interceptor';
import { DetailsComponent } from './core/components/details/details.component';
import { HomeComponent } from './core/components/home/home.component';
import { HomeService } from './core/components/home/home.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuthModule } from '@auth0/auth0-angular';
import { CookieService } from 'ngx-cookie-service';
import { UtilService } from './shared/util.service';
import { DetailsService } from './core/components/details/details.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgToggleModule } from 'ng-toggle-button';
import { ErrorToastComponent } from './shared/error-toast/error-toast.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { SuccessToastComponent } from './shared/success-toast/success-toast.component';
import { ToastMessageComponent } from './shared/toast-message/toast-message.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import * as DataQuestConst from 'src/app/dataQuest.constants';
import { TokenService } from './shared/token.service';
import { MenusComponent } from './core/components/menus/menus.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
//import { environment } from 'src/environments/environment.prod';

  export const authConfig = environment.type.includes('qa')
  ? environment.indusAuth
  : environment.type.includes('uat')
  ? environment.uatAuth
  : environment.auth;
//export const authConfig =  environment.auth;

  export const platformApiUrl = environment.type.includes('qa')
  ? DataQuestConst.QA_PLATFORM_API_URL
  : environment.type.includes('uat')
  ? DataQuestConst.UAT_PLATFORM_API_URL
  : DataQuestConst.DEV_PLATFORM_API_URL;
//export const platformApiUrl =  DataQuestConst.PROD_PLATFORM_API_URL;

@NgModule({
  declarations: [
    AppComponent,
    MenusComponent,
    HomeComponent,
    DropdownDirective,
    DetailsComponent,
    ToastMessageComponent,
    SuccessToastComponent,
    FooterComponent,
    ErrorToastComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ScrollingModule,
    NgSelectModule,
    NgToggleModule.forRoot(),
    AuthModule.forRoot(authConfig),
    AutocompleteLibModule
  ],
  providers: [
    TokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    UtilService,
    HomeService,
    DetailsService,
    CookieService,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
