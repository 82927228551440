import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailsService } from './details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from '../home/home.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/shared/util.service';
import { SaveDraftRequest, UserInput } from './draftrequest.model';
import { Filter } from './draftrequest.model';
import { authConfig } from 'src/app/app.module';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  viewsLists: any = [];
  viewId: any;
  selectedFields: number | undefined;
  userId: number = null;
  operators: any = [];
  fields: any = [];
  selectedOperators: number | undefined;
  requestForm!: FormGroup;
  tabOptions: string[] = ['Filters', 'MySQL'];
  isOptionValue: string = 'Filters';
  limit: number = 100;
  page: number = 1;
  viewName: any = '';
  status: boolean = false;
  indexValue: any;
  viewType: string = '';
  previewResults: any = [];
  previewSchemas: any = [];
  result: any = '';
  condition: string = null;

  options: string[] = ['AND', 'OR'];
  selectedOptions: string[] = [];
  isChecked: boolean = true;
  andOrValue: any;
  errorMessage: string = null;
  warningMessage: string = null;
  errorMessageSubmit: string = null;
  successMessage: string = null;
  activeTabIndex = 0;
  submitReqId: number = null;
  scrollTop: boolean = false;
  lastUpdatedDate: string = null;
  rowIndex = 0;
  formlist: any = [];
  setValueOptional: string = 'AND';
  returnValue: string = '';
  isSubmitButtonDisabled: boolean = false;
  submitDisabledFlag: boolean = false;
  data: any
  customLabel:string='';
  keyword = 'name';
  @ViewChild('auto') auto:any;
  allTags:any=[]
  tagsByViewId:any=[]
  addtagFlag=false;
  savedraftrequestId: any;
  authId: any;
  requestId:any= null;
  userInput: UserInput = {
    condition: '',
    customLabel:'',
    filters: []
  };
  savedraftrequest: SaveDraftRequest = {
    userId: 0,        // Initialize with default or real values
    viewId: 0,        // Initialize with default or real values
    authId: '', // Initialize with default or real values
    userInput: this.userInput,// Initialize userInput
    requestId: null
  };
  requestUpdatedDate: any;
  updatedDate: any;
  timeInterval: string;
  requestDesc: any = null;
  autocompleteText:any=""
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private detailsService: DetailsService,
    private spinner: NgxSpinnerService,
    private utilService: UtilService,
    private homeService: HomeService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {
    this.spinner.show();
    this.userId = +sessionStorage.getItem('userId');
    this.authId = sessionStorage.getItem('id');
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const token = params.token;
      if (token != undefined) {
        sessionStorage.setItem('parentToken', token);
        let reqIdStr = router.url?.split("/")[2]
        let reqId = reqIdStr?.split("?")
        this.cleanURL(router.url.split("/")?.[1], reqId[0]);
        window.location.reload();
      }
    });
    if (router.url.split("/")?.[1] === "jobrequest") {
      this.detailsService.getEditRequestDetails('DataQuest-' + router.url?.split("/")[2].split("?")?.[0]).subscribe({
        next: res => {
          let resp = JSON.parse(res.result.requestDesc)
          this.requestUpdatedDate=res.result.requestUpdationDate;
          this.customLabel=resp.userInput.customLabel;
          this.getTimeInterval();
          this.condition=resp.userInput.condition;
          this.viewId = resp.viewId;
          this.detailsService.viewId.next(resp)

          this.homeService.getAllViews(this.userId).subscribe((res: any) => {
            for (const element of res.result) {
              if (element.id == this.viewId) {
                const viewResResult = element;
                this.viewsLists = viewResResult;
                this.viewName = this.viewsLists.displayViewHeading;
                this.viewType = this.viewsLists.viewType;
                this.lastUpdatedDate = this.viewsLists.lastUpdateDate;
                this.requestId=`${router.url?.split("/")[2].split("?")?.[0]}`;
                this.loadPreviewResults();
                this.spinner.hide();
              }
            }
          });
        },
        error: err => { },
        complete: () => { }
      })
    } else {
      this.viewId = +this.route.snapshot.params['id'];
      this.homeService.getAllViews(this.userId).subscribe((res: any) => {
        for (const element of res.result) {
          if (element.id == this.viewId) {
            const viewResResult = element;
            this.viewsLists = viewResResult;
            this.viewName = this.viewsLists.displayViewHeading;
            this.viewType = this.viewsLists.viewType;
            this.lastUpdatedDate = this.viewsLists.lastUpdateDate;
            this.loadPreviewResults();
            this.spinner.hide();
          }
        }
      });
    }   
    this.utilService.goToTop();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.viewId) {
      this.loadFields();
      this.loadOperator();
    } else {
      this.detailsService.viewId.subscribe(res => {
        this.viewId = res.viewId;
        this.loadFields();
        this.loadOperator();
      })
    }
    // this.loadOperator();
    this.initForm();
    this.onAddNew();
    this.trackFormArrayChanges();
    // this.addTags();
    // this.removeTags();
  }
  ngAfterViewInit() {
    this.detailsService.viewId.subscribe(res => {
      this.viewId = res.viewId;
      // this.requestDesc = JSON.parse(res.result.requestDesc)
      this.data = res.userInput.filters;
      this.data.forEach((x: any, index: any) => {
        const flag = (x.type === "sql");
        this.onClickSetValue(flag ? 'MySQL' : 'Filters', index, flag ? 1 : 0);
        if (index > 0) {
          this.allFields.push(
            new FormGroup({
              fieldName: new FormControl(null, [Validators.required]),
              operator: new FormControl(null, Validators.required),
              fieldValue: new FormControl(null, [Validators.required]),
              customQuery: new FormControl(null, [Validators.required]),
              andOrValue: new FormControl(),
              activetab: new FormControl(flag ? 'MySQL' : 'Filters'),
            })
          )

        }
      })

      this.data.forEach((x: any, index: any) => {
        const flag = (x.type === "sql");
        return (<FormArray>this.requestForm.get('allFields')).at(index).patchValue({
          fieldName: x['fieldName'],
          operator: x['operator'],
          fieldValue: !flag ? x['value'] : '',
          customQuery: flag ? x['value'] : '',
          andOrValue: x['logicalOperator'] == "AND" ? false : true
        })

      })


    })
    // this.getAllTags();
    this.getTagsByView();
  }
  trackFormArrayChanges() {
    this.allFields.valueChanges.subscribe((changes) => {
      this.isSubmitButtonDisabled = false;
    });
  }

  isSubmitDisabledYes(): boolean {
    const firstField = this.allFields.at(0);
    return firstField.invalid || firstField.value.fieldName.trim() === '';
  }


  getControls() {
    return (this.requestForm.get('allFields') as FormArray).controls;
  }

  onErrorClose(): void {
    this.errorMessage = null;
    this.warningMessage = null;
  }

  loadPreviewResults() {
    this.spinner.show();
    this.detailsService
      .getPreviewResults(
        this.userId,
        this.viewType,
        this.viewName,
        this.condition,
        this.limit,
        this.page,
        this.customLabel,
        this.viewId,
        this.authId,
        this.requestId,
        this.requestForm.get('allFields').value
      )
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.initializeSaveDraftRequest();
            this.warningMessage = null;
            this.errorMessage = res.message;
            this.isSubmitButtonDisabled = false;
            this.submitDisabledFlag = false;
            this.spinner.hide();

          } else if (res.code === 204) {
            this.initializeSaveDraftRequest();
            this.errorMessage = null;
            this.warningMessage = res.message;
            this.isSubmitButtonDisabled = false;
            this.submitDisabledFlag = false;
            this.spinner.hide();

          } else {
            const previewRes = res;
            this.previewResults = previewRes.result;
            this.previewSchemas = previewRes.schema;
            this.errorMessage = null;
            this.warningMessage = null;
            this.spinner.hide();
            if (this.submitDisabledFlag) {
              this.isSubmitButtonDisabled = true;
            }

          }
          if (this.scrollTop) {
            this.utilService.autoScrollUp();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.isSubmitButtonDisabled = true;
        },
      });
  }



  getPreviewKeys() {
    return this.previewResults && this.previewResults.length > 0
      ? Object.keys(this.previewResults[0])
      : [];
  }

  loadFields() {
    this.spinner.show();
    this.detailsService
      .getField(this.viewId, this.userId)
      .subscribe((res: any) => {
        const fieldRes = res;
        this.fields = fieldRes.result;
        this.spinner.hide();
      });
  }

  loadOperator() {
    this.spinner.show();
    this.detailsService.getOperators().subscribe((res: any) => {
      const operatorRes = res;
      this.operators = operatorRes.result;
      this.spinner.hide();
    });
  }

  onAddNew() {
    (<FormArray>this.requestForm.get('allFields')).push(
      new FormGroup({
        fieldName: new FormControl(null, [Validators.required]),
        operator: new FormControl(null, Validators.required),
        fieldValue: new FormControl(null, [Validators.required]),
        customQuery: new FormControl('', [Validators.required]),
        andOrValue: new FormControl(),
        activetab:new FormControl('')
      })
    );
    if (this.formlist.length != this.allFields.length) {
      for (
        let i = 0;
        i < this.allFields.length - this.formlist.length + 1;
        i++
      ) {
        this.formlist.push(0);
      }
    }
  }

  onDeleteRow(index: number) {
    if (this.allFields.length > 1) {
      (<FormArray>this.requestForm.get('allFields')).removeAt(index);
      this.formlist.splice(index, 1);
    }
  }

  toggleClick(index: any) {
    this.isChecked = !this.isChecked;
    this.result = '';
  }

  private initForm() {
    this.requestForm = new FormGroup({
      allFields: new FormArray([]),
      customQuery: new FormControl(''),
      andOrValue: new FormControl(),
    });
    this.formlist = new Array(this.allFields.length).fill(0);
  }

  get allFields() {
    return this.requestForm.get('allFields') as FormArray;
  }

  onApply() {
    this.scrollTop = false;
    this.loadCondition();
    this.loadPreviewResults();
  }

  loadCondition() {
    if (this.result) {
      this.result = '';
    }
    this.savedraftrequest.userInput.filters.length = 0;
    var seq = 1;
    const size = this.allFields.controls.length;
    const allFields = this.allFields.controls.map((allField:any, index) => {
      let myFilter: Filter = {
        seqId: 0, // Initialize with default or real values
        type: '', // Will be set based on conditions
        value: '' // Will be set based on conditions
      };

      let fieldName = allField.get('fieldName').value;
      let operator = allField.get('operator').value;
      let fieldValue = allField.get('fieldValue').value;
      let customQuery = allField.get('customQuery').value;
      if (allField.value.activetab == "MySQL") {
        fieldName = "";
        operator = "";
        fieldValue = "";
        customQuery = allField.get('customQuery').value;
     } else if (allField.value.activetab == "Filters") {
        fieldName = allField.get('fieldName').value;
        operator = allField.get('operator').value;
        fieldValue = allField.get('fieldValue').value;
        customQuery = "";
     }
      this.andOrValue =
        allField.get('andOrValue').value === true ? 'OR' : 'AND';

      if (seq !== 1)
        myFilter.logicalOperator = this.andOrValue;

      myFilter.seqId = seq;

      if ((fieldName == ""|| fieldName == undefined) && (operator == ""||operator==undefined) && (fieldValue == "" || fieldValue==undefined )) {
        myFilter.value = customQuery;
        myFilter.type = 'sql';
        this.returnValue += `${this.andOrValue} ${customQuery} `;
      } else if (operator === 'LIKE') {
        
        myFilter.type = 'filter';
        myFilter.fieldName = fieldName;
        myFilter.value = fieldValue;
        myFilter.operator = operator;

        this.returnValue += `${this.andOrValue} ${fieldName} ${operator} ('%${fieldValue}%') ${customQuery} `;
      } else if (operator.includes('IN')) {

        myFilter.type = 'filter';
        myFilter.fieldName = fieldName;
        myFilter.value = fieldValue;
        myFilter.operator = operator;
        let str = fieldValue;
        const splittedArray = str.split(',');

        let finalString = '(';
        splittedArray.forEach((str1: any) => {
          finalString = finalString + "'" + str1 + "',";
        });
        finalString = finalString.slice(0, -1);
        finalString = finalString + ')';
        this.returnValue += `${this.andOrValue} ${fieldName} ${operator} ${finalString} ${customQuery} `;
      } else {
        myFilter.type = 'filter';
        myFilter.fieldName = fieldName;
        myFilter.value = fieldValue;
        myFilter.operator = operator;

        this.returnValue += `${this.andOrValue} ${fieldName} ${operator}` +(fieldValue!=""?`('${fieldValue}')`:'')+` ${customQuery} `;


      }
      this.savedraftrequest.userInput.filters.push(myFilter);

      seq = seq + 1;

    });
    //this.savedraftrequest.userInput = this.userInput;
    let splitStr = this.returnValue.slice(0, this.returnValue.indexOf(' '));

    this.result = this.returnValue.substring(
      splitStr.length + 1,
      this.returnValue.length
    );
    this.condition = this.result.trim();
    if (this.condition === '') {
      this.condition = null;
    }
    this.returnValue = '';
    this.scrollTop = true;
    this.submitDisabledFlag = true;
  }

  onSubmit() {
    this.loadCondition();
    this.spinner.show();
    this.detailsService
      .getSubmit(
        this.userId,
        this.condition,
        this.requestId,
        this.customLabel,
        this.viewId,
        this.authId,
        this.requestForm.get('allFields').value
      )
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.errorMessageSubmit = res.message;
            this.utilService.goToTop();
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 5000);
          } else {
            this.requestId = null;
            const submitRes = res;
            const submitResult = submitRes.result;
            this.submitReqId = submitResult.requestId;
            this.errorMessageSubmit = null;
            this.successMessage = `Request ID ${this.submitReqId} generated successfully for the submitted query.`;
            this.spinner.hide();
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;
              this.router.navigate(['/home']);
            }, 5000);
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }

  onClickSetValue(tabValue: any, rowindex: any, columnindex: any,onClick=false,item:any=null) {
    this.activeTabIndex = columnindex;
    this.rowIndex = rowindex;
    this.formlist[rowindex] = columnindex;
    this.allFields?.at(rowindex)?.patchValue({
      activetab:tabValue
    })

    if (this.allFields.length > 0 && !onClick) {
      return
    } else {
      // used to reset the fields at index position
      // if (tabValue === 'MySQL') {
      //   (<FormArray>this.requestForm.get('allFields')).at(rowindex).patchValue({
      //     fieldName: null,
      //     operator: null,
      //     fieldValue: null,
      //   });
      // } else if (tabValue === 'Filters') {
      //   (<FormArray>this.requestForm.get('allFields'))
      //     .at(rowindex)
      //     .get('customQuery')
      //     .patchValue('');
      // }
    }

  }

  goBack() {
    this.router.navigate(['/home']);
  }

  isPreviewEnabled(): boolean {
    return this.allFields.controls.every((group: any) => {
      return (
        (group.get('fieldName').valid &&
          group.get('operator').valid &&
          group.get('fieldValue').valid) ||
        group.get('customQuery').valid
      );
    });
  }

  isSaveDisabled(): boolean {
    return this.allFields.controls.every((group: any) => {
      if (this.isPreviewEnabled() && !(!this.isSubmitButtonDisabled &&
        this.isSubmitEnabled() &&
        this.isSubmitDisabledYes())) {
        return false;
      }
      else
        return true;
    });
  }

  isSubmitEnabled(): boolean {
    return this.allFields.controls.some((group: any) => {
      return (
        group.get('fieldName')?.value !== null ||
        group.get('operator')?.value !== null ||
        (group.get('fieldValue')?.value !== null &&
          group.get('fieldValue')?.value !== '') ||
        group.get('customQuery')?.value !== ''
      );
    });
  }

  onSaveDraft() {
    this.initializeSaveDraftRequest();
    this.loadCondition();
    this.spinner.show();
    this.savedraftrequest.userId = this.userId;
    this.savedraftrequest.viewId = this.viewId;
    this.savedraftrequest.authId = this.authId;
    this.savedraftrequest.userInput.customLabel = this.customLabel;
    this.savedraftrequest.userInput.condition = this.condition;
    if (this.requestId !== '' && this.requestId !== null && this.requestId !== undefined)
      this.savedraftrequest.requestId = "DataQuest-" + this.requestId;
    this.detailsService
      .getSaveDraft(
        this.savedraftrequest
      )
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.errorMessageSubmit = res.message;
            this.utilService.goToTop();
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 5000);
          } else {
            const savedraftres = res;
            this.requestId = savedraftres.result.requestId;
            this.requestUpdatedDate = savedraftres.result.requestUpdatedDate;
            this.getTimeInterval();
            this.errorMessageSubmit = null;
            this.successMessage = `${savedraftres.message}`;
            this.spinner.hide();
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;

            }, 5000);
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }

  initializeSaveDraftRequest() {
    this.savedraftrequest = {
      userId: 0,
      viewId: 0,
      authId: 'default',
      userInput: {
        condition: '',
        filters: []
      }
    };
  }

  getTimeInterval() {
    let that = this;

    if (this.requestId != undefined && this.requestUpdatedDate != undefined) {
      this.updatedDate = this.requestUpdatedDate;
    }
    let id = setInterval(function () {
      if (that.userId) {
        let date = new Date(that.requestUpdatedDate);
        let current = new Date();

        // Create a UTC version of the current date
        let currentUtc = new Date(
          Date.UTC(
          current.getUTCFullYear(),
          current.getUTCMonth(),
          current.getUTCDate(),
          current.getUTCHours(),
          current.getUTCMinutes(),
          current.getUTCSeconds()
          )
        );

        let diffInMs = Math.abs(currentUtc.getTime() - date.getTime());
        let diffInSeconds = Math.floor(diffInMs / 1000);
        let diffInMinutes = Math.floor(diffInSeconds / 60);
        let diffInHours = Math.floor(diffInMinutes / 60);
        let diffInDays = Math.floor(diffInHours / 24);

        if (diffInDays > 0) {
          that.timeInterval = `${diffInDays} days`;
        } else if (diffInHours > 0) {
          that.timeInterval = `${diffInHours} hours`;
        } else if (diffInMinutes > 0) {
          that.timeInterval = `${diffInMinutes} minutes`;
        } else {
          that.timeInterval = `${diffInSeconds} seconds`;
        }
      } else {
        clearInterval(id);
      }
    }, 5000);
  }

  cleanURL(routeUrl: any, id: any) {
    this.location.replaceState(`${routeUrl}/${id}`);
  }


  selectEvent(item: any) {
    this.addTags(item);

  }

  onChangeSearch(search: any) {
  }

  onFocused(e: any) {
  }

  getAllTags(){
    this.detailsService.getAllTags().subscribe(res=>{
      if(res){
        this.allTags=res.result.map((x:any)=>({id:x.id,name:x.tagName})).filter((md:any) => 
          this.tagsByViewId.every((fd:any) => fd.id !== md.id))
      }
    })
  }
  getTagsByView() {
    this.addtagFlag = false;
    if (!this.viewId) {
      this.detailsService.viewId.subscribe(res => {
        this.viewId = res.viewId;
        this.detailsService.getTagsByView(this.viewId).subscribe(res => {
          if (res) {
            this.tagsByViewId = res.result
            this.autocompleteText="";
          }
          this.getAllTags()
        })
      })
    } else {
      this.detailsService.getTagsByView(this.viewId).subscribe(res => {
        if (res) {
          this.tagsByViewId = res.result
          this.autocompleteText="";
        }
        this.getAllTags()
      })
    }
  }
  addTags(item:any,addNew=false){
    if(addNew){
      this.detailsService.addTags(this.viewId,[],[item]).subscribe(res=>{
        if(res){
          this.auto.clear()
          this.getTagsByView();
        }
      })
    }else{
      this.detailsService.addTags(this.viewId,[item.id],[]).subscribe(res=>{
        if(res){
          this.auto.clear()
          this.getTagsByView();
        }
      })
    }
  }

  
  removeTags(id:any){
    this.detailsService.removeTags(this.viewId,id).subscribe(res=>{
      if(res){
        // this.auto.clear()
        this.getTagsByView(); 
      }
    })
  }
  showAddInput(){
    this.addtagFlag=true;
  }
  addNewTag(e:any,flag=false){
    if(flag==true){
      this.addTags(e,true)
    }else{
      this.addTags(e.target.value,true)
    }
  } 
  setCustomLabel(e:any){
    this.customLabel=e.target.value;
  }
  setText(e:any){
    this.autocompleteText=e.target.value;
  }
}


