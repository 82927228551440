import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HomeService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;',
    }),
  };

  getViewsOnSearch(userId: any, searchElement: any, viewType: any, tags: any) {
    return this.http.post<any>(`${environment.apiUrl}/v1/search`, {
      userId,
      searchElement,
      viewType,
      tags,
    });
  }

  getViewTypes(id: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/getViewTypesByUser`,
      { id },
      {}
    );
  }

  getAllTags(userId: number) {
    return this.http.post<any>(`${environment.apiUrl}/v1/tags`, { userId }, {});
  }

  getAllViews(id: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/getViewsByUser`,
      { id },
      {}
    );
  }
}
